import React from 'react';
import { CustomTooltip } from 'components/CustomTooltip';
import dangerIcon from '../../../assets/images/danger-triangle.svg';
import './style.css';

interface DuplicateActivityNameProps {
  name?: string;
}

export const DuplicateActivityName = ({ name }: DuplicateActivityNameProps) => {
  return (
    <div className="duplicate-activity">
      <>{name}</>
      <div style={{ marginLeft: 'auto' }}>
        <CustomTooltip id="dangerTriangle" message="Attività già registrata">
          <img src={dangerIcon} alt="dangerTriangle" />
        </CustomTooltip>
      </div>
    </div>
  );
};
