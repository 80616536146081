import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { navigate } from 'store/navigationSlice';
import { store } from 'store/store';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const isDashboard = config.url?.includes('backoffice');
    if (isDashboard) {
      try {
        const token = store.getState().auth.accessToken;
        config.headers.Authorization = `Bearer ${token}`;
      } catch (e) {
        console.error(e);
        Promise.reject(e);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          store.dispatch(navigate('/error-auth'));
          break;
        case 403:
          store.dispatch(navigate('/error-auth'));
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default api;
